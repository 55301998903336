import { Link } from 'react-router-dom';
import Layout from '../components/layout';
import AnimatedElement from '../components/AnimatedElement';

const Khalifa = () => {
    const handleReload = (route) => {
        window.location.href = route;
    }

    return (
        <Layout>
            <main className='conteneur-khalifa'>
                <section className='poster'>
                    <AnimatedElement finalState={'translateY(0) rotate(180deg)'} initialState={'translateY(-40px) rotate(180deg)'} className="date">
                        <p>(11.23)<br /> *date de sortie</p>
                    </AnimatedElement>
                    <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(-40px)"} className="block-soundbox">
                        <h3>SoundBox</h3>
                        <p className='poster-text'>Khalifa<br />Cet artiste originaire d'Annecy a su nous<br />
                            surprendre avec son style musical envoûtant<br />
                            et sa plume magnifique.</p>
                    </AnimatedElement>
                    <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(40px)"} className="img-block">
                        <img src="../assets/khalifa/Photo 3 5.png" alt="Project 4" />
                        <p className='under-img'>“ Blessé le coeur remplit de maladresse, je me demande ce qu’il se passe dans ma tête. ”</p>
                    </AnimatedElement>
                    <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(40px)"}>
                        <p className='text'><strong>009</strong></p>
                    </AnimatedElement>
                </section>

                <section className='scroll-child showcase'>
                    <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(100px)"} className="title">
                        <h3>Khalifa</h3>
                    </AnimatedElement>
                    <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(40px)"} className="img-block">
                        <img src="../assets/khalifa/Photo 2 6.png" alt='khalifa' />
                    </AnimatedElement>
                </section>

                <section className='scroll-child profil'>
                    <section className='first-part'>
                        <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(-40px)"}>
                            <div className='work'>
                                <p><strong>Work <br></br>for</strong></p>
                                <h3>Jucy</h3>
                            </div>
                            <h4>lab</h4>
                        </AnimatedElement>
                        <AnimatedElement finalState={"translateY(0) rotate(180deg)"} initialState={"translateY(40px) rotate(180deg)"} className='number'>
                            <p>009</p>
                        </AnimatedElement>
                    </section>
                    <section className='second-part'>
                        <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(-40px)"} className='block-text'>
                            <p>
                                Statistiques globales Instagram & Youtube :<br />+67 commentaires <br /> +14.106 vues <br />+405 likes
                            </p>
                        </AnimatedElement>
                        <div className='block-background'></div>
                        <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(40px)"} className='block-img'>
                            <img src="../assets/khalifa/Photo 4 4.png" alt="profil de khalifa" />
                        </AnimatedElement>
                    </section>
                </section>

                <section className='scroll-child clip'>
                    <iframe
                        className='video-khalifa'
                        title="SoundBox #9 - KHALIFA"
                        src="https://www.youtube.com/embed/ynMkvDfV--8"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        loading='lazy'
                        allowFullScreen
                    ></iframe>
                </section>
                <section className='scroll-child other-soundbox'>
                    <Link onClick={() => handleReload('/dibby')}>
                        <img src='../assets/khalifa/end.png' alt="montage des projets jucyLab" ></img>
                    </Link>
                </section>
            </main>
        </Layout>
    );
};
export default Khalifa;
