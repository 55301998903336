import React, { useState } from 'react';
import Layout from '../components/layout';
import AnimatedElement from '../components/AnimatedElement';

const JProtectCar = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const images = [
        "../assets/3jprotectcar/slider1.webp",
        "../assets/3jprotectcar/slider2.webp",
        "../assets/3jprotectcar/slider3.webp",
        "../assets/video/JProtectCar_Slide_Rasso.mp4"
    ];

    const isVideo = (src) => {
        return src.endsWith(".mp4");
    };

    const handlePrevClick = () => {
        const newIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const handleNextClick = () => {
        const newIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    return (
        <Layout>
            <main className='conteneur-jprotectcar'>
                <section className='hero'>
                    <a href='https://www.instagram.com/3jprotectcar?igsh=MXVpcjk0YzJ5N3hkZg%3D%3D' target='_blank' rel="noopener noreferrer">
                        <section className='cover'>
                            <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(-40px)"}>
                                <img src="../assets/3jprotectcar/3Jprotectcar.webp" alt="covering de la marque porshe" />
                            </AnimatedElement>
                            <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(40px)"}>
                                <p>Covering & Detailing</p>
                            </AnimatedElement>
                        </section>
                    </a>
                </section>

                <section className='scroll-child profil'>
                    <article className='block-text'>
                        <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(-40px)"}>
                            <p>Plonger dans l'univers du covering,
                                cette activité est bien plus qu'une
                                simple passion pour notre client,
                                c'est une véritable obsession pour
                                l'excellence<br />et l'esthétique.
                            </p>
                        </AnimatedElement>
                        <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(-40px)"}>
                            <p>
                                En travaillant avec lui au fil du temps,
                                j'ai eu le privilège de découvrir sa
                                vision audacieuse et sa volonté
                                constante de repousser les limites
                                de la créativité dans ce domaine.
                            </p>
                        </AnimatedElement>
                        <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(40px)"}>
                            <p>
                                Les projets présentés sont le fruit
                                de cette collaboration inspirante,
                                mettant en lumière une pratique
                                mêlant esthétique et technique.
                            </p>
                        </AnimatedElement>
                    </article>
                    <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(40px)"} className="block-video">
                        <video
                            src="../assets/video/JProtectCar_Reels_Porshe_Macan.mp4"
                            autoPlay
                            muted
                            controls
                        />
                    </AnimatedElement>
                </section>
                <section className='scroll-child carousel'>
                    <article className='title'>
                        <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(-40px)"} className='date'>
                            <p>07/04/24</p>
                        </AnimatedElement>
                        <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(-40px)"}>
                            <h3>RASSO </h3>
                            <h3 className='district'>DISTRICT</h3>
                        </AnimatedElement>
                    </article>
                    <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(40px)"} className='slider'>
                        {isVideo(images[currentIndex]) ? (
                            <video
                                src={images[currentIndex]}
                                autoPlay
                                loop
                                className="video-slide"
                            />
                        ) : (
                            <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
                        )}
                        <div className='arrow'>
                            <svg onClick={handlePrevClick} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><path fill="currentColor" fillRule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12m11.707-2.293a1 1 0 0 0-1.414-1.414l-3 3a1 1 0 0 0 0 1.414l3 3a1 1 0 0 0 1.414-1.414L11.414 12z" clipRule="evenodd" /></svg>
                            <svg onClick={handleNextClick} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><path fill="currentColor" fillRule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12m8.293 2.293a1 1 0 1 0 1.414 1.414l3-3a1 1 0 0 0 0-1.414l-3-3a1 1 0 1 0-1.414 1.414L12.586 12z" clipRule="evenodd" /></svg>
                        </div>
                    </AnimatedElement>
                </section>

                <section className='scroll-child real'>
                    <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(-40px)"} className='real-title'>
                        <h2>APRILIA</h2>
                        <h3>RS 660</h3>
                    </AnimatedElement>
                    <section className='real-block-video'>
                        <div className="block-video">
                            <AnimatedElement finalState={"translateX(0) rotate(180deg)"} initialState={"translateX(-40px) rotate(180deg)"} className='date'>
                                <p>25/08/24</p>
                            </AnimatedElement>
                            <video
                                src="../assets/video/JProtectCar_Reels_Aprilia_RS660.mp4"
                                autoPlay
                                muted
                                controls
                            />
                        </div>
                    </section>
                </section>

                <section className='scroll-child covering'>
                    <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(-40px)"} className="title">
                        <h2>Covering</h2>
                    </AnimatedElement>
                    <div className='block-img'>
                        <div className='block-small-img'>
                            <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(-40px)"} className="small-img">
                                <img src="../assets/3jprotectcar/covering-1.jpg" alt="Affichage des compteurs et des commandes d'une moto" />
                            </AnimatedElement>
                            <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(40px)"} className="small-img">
                                <img src="../assets/3jprotectcar/covering-2.jpg" alt="Affichage des compteurs et des commandes d'une moto" />
                            </AnimatedElement>
                        </div>
                        <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(-40px)"}  className='large-img'>
                            <img src="../assets/3jprotectcar/covering-3.jpg" alt="Affichage des compteurs et des commandes d'une moto" />
                        </AnimatedElement>
                    </div>
                    <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(40px)"} className="desc">
                        <p>*au couleur et finitions<br />de Lamborghini </p>
                    </AnimatedElement>
                </section>
            </main>
        </Layout >
    );
};

export default JProtectCar;
