import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/home';
import Dibby from './pages/Dibby';
import Jprotectcar from './pages/JProtectCar';
import Poissonchat from './pages/PoissonChat';
import Khalifa from './pages/Khalifa';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' exact element={<Home />} />
        <Route path='/dibby' exact element={<Dibby />} />
        <Route path='/jprotectcar' exact element={<Jprotectcar />} />
        <Route path='/poissonchat' exact element={<Poissonchat />} />
        <Route path='/khalifa' exact element={<Khalifa />} />
        <Route path='/*' exact element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
