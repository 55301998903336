import React, { useState, useEffect, useRef } from "react";
import Layout from '../components/layout';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import AnimatedElement from "../components/AnimatedElement";

const Home = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isCarouselVisible, setIsCarouselVisible] = useState(false);
  const carouselRef = useRef(null);
  const intervalRef = useRef(null);

  const slides = [
    {
      imgSrc: "/assets/home/about-me-carousel-1.webp",
      title: "ABOUT ME",
      text: "En tant que directeur artistique évoluant au sein d'un label de musique basé à Genève, j'ai eu le privilège de participer à une multitude de projets passionnants.",
      class: "first-img"
    },
    {
      imgSrc: "/assets/home/about-me-carousel-2.webp",
      text: "Ce qui m’intéresse le plus dans mon travail, c’est la façon dont un simple projet peut se transformer en un défi créatif, grâce à sa direction artistique et aux contraintes que l’on rencontre.",
      class: "second-img"
    },
    {
      imgSrc: "/assets/home/about-me-carousel-3.webp",
      text: "Je me met toujours au défi d’apporter à travers mes vidéos un aspect des plus esthétiques et toujours diversifié.",
      class: "third-img"
    }
  ];

  useEffect(() => {
    const currentCarousel = carouselRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsCarouselVisible(entry.isIntersecting);
      },
      { threshold: 0.5 } // Adjust as necessary
    );

    if (currentCarousel) {
      observer.observe(currentCarousel);
    }

    return () => {
      if (currentCarousel) {
        observer.unobserve(currentCarousel);
      }
    };
  }, []);

  useEffect(() => {
    const startInterval = () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      intervalRef.current = setInterval(() => {
        if (isCarouselVisible) {
          setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }
      }, 8000); // Set the interval for 8 seconds
    };

    startInterval();

    return () => clearInterval(intervalRef.current);
  }, [isCarouselVisible, slides.length]);

  const handleDotClick = (index) => {
    setCurrentSlide(index);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      if (isCarouselVisible) {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
      }
    }, 8000); // Set the interval for 8 seconds
  };

  const handleReload = (route) => {
    window.location.href = route;
  }

  return (
    <Layout>
      <div className="home-page">
        <div className="hero">
          <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(-40px)"}>
            <p>THE DESIGNER</p>
          </AnimatedElement>
          <h1 className="animated-letter">MR.RIBLE</h1>
        </div>
        <section className="scroll-child poster">
          <div className="title_top">
            <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(-40px)"}>
              <p>AUDIOVISUAL</p>
            </AnimatedElement>
            <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(40px)"}>
              <p>DA</p>
            </AnimatedElement>
            <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(40px)"}>
              <p>GRAPHIC DESIGNER</p>
            </AnimatedElement>
          </div>
          <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(40px)"} className="images">
            <img className="profil" src="./assets/home/poster-artist.webp" alt="profil du directeur artistique" />
            <img className="tree" src="./assets/home/poster-tree.webp" alt="arbre" />
          </AnimatedElement>
          <div className="title_bottom">
            <div className="content">
              <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(-40px)"}>
                <p>PHOTOGRAPHY</p>
              </AnimatedElement>
              <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(40px)"}>
                <p>EDITOR</p>
              </AnimatedElement>
            </div>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.5 }}
              className="black-line_block"
            >
              <hr className="black-line" data-aos="fade-left-right" />
            </motion.div>
          </div>
        </section>

        <section
          ref={carouselRef}
          className='scroll-child about'
          id="about"
        >
          <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(-40px)"}>
            <h2>ABOUT ME</h2>
          </AnimatedElement>
          <article
            className={`about-content ${slides[currentSlide]?.class}`}
          >
            <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(40px)"} className="block-img">
              <img
                src={slides[currentSlide].imgSrc}
                alt="Carrousel"
              />
            </AnimatedElement>
            <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(40px)"}>
              <p>{slides[currentSlide].text}</p>
            </AnimatedElement>
          </article>
          <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(40px)"} className="carousel-dots">
            {slides.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === currentSlide ? 'active' : ''}`}
                onClick={() => handleDotClick(index)}
                role="button"
                aria-label={`Go to slide ${index + 1}`}
                aria-current={index === currentSlide ? 'true' : 'false'}
              ></span>
            ))}
          </AnimatedElement>
        </section>
        <section className="scroll-child projects" id="project">
          <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(-40px)"}>
            <h2 >PROJECTS</h2>
          </AnimatedElement>
          <div className="card-project">
            <Link onClick={() => handleReload('/dibby')}>
              <section className="project-block">
                <div className="title-project">
                  <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(-40px)"}>
                    <img src="./assets/home/projects-work-1.webp" alt="projet jucyLab" />
                  </AnimatedElement>
                  <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(-40px)"}>
                    <h5>Jucylab</h5>
                  </AnimatedElement>
                </div>
                <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(40px)"} className="text-card">
                  <p>
                    Passionnés de musique et de vidéo, nous avons créé ce média musical basé à Genève depuis plus d'un an, offrant à notre petite équipe l'opportunité de mettre en pratique nos passions et nos talents.
                  </p>
                </AnimatedElement>
              </section>
            </Link>
            <Link onClick={() => handleReload('/jprotectcar')}>
              <section className="project-block">
                <div className="title-project">
                  <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(-40px)"}>
                    <img src="./assets/home/projects-work-2.webp" alt="projet 3jProtectcar" />
                  </AnimatedElement>
                  <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(40px)"}>
                    <h5>3Jprotectar</h5>
                  </AnimatedElement>
                </div>
                <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(40px)"} className="text-card">
                  <p>
                    Plonger dans l'univers du covering,
                    cette activité est <br /> bien plus qu'une
                    simple passion pour notre client,<br />
                    c'est une véritable obsession pour
                    l'excellence et l'esthétique.
                  </p>
                </AnimatedElement>
              </section>
            </Link>
          </div>
        </section>
      </div>
    </Layout >
  );
};

export default Home;