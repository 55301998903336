import React, { useEffect, useRef, useState } from 'react';
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import AnimatedElement from './AnimatedElement';

const Layout = ({ children }) => {
  const headerRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  const handleHomeClick = (e) => {
    e.preventDefault();

    const element = document.getElementById("home");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  const handleAboutClick = (e) => {
    e.preventDefault();

    if (location.pathname !== "/") {
      navigate("/#about", { replace: true });

      setTimeout(() => {
        const element = document.getElementById("about");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 300);
    } else {
      const element = document.getElementById("about");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  const handleWorkClick = (e) => {
    e.preventDefault();

    if (location.pathname !== "/") {
      navigate("/#project", { replace: true });

      setTimeout(() => {
        const element = document.getElementById("project");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 300);
    } else {
      const element = document.getElementById("project");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    const header = headerRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }
      },
      { threshold: [0.1] } // Le seuil où l'intersection est considérée comme "passée"
    );

    if (header) {
      observer.observe(header);
    }

    return () => {
      if (header) {
        observer.unobserve(header);
      }
    };
  }, []);

  return (
    <>
      <header ref={headerRef} className="scroll-child" id="home">
        <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(-40px)"} className="home-nav">
          <nav>
            <NavLink to="/" aria-label="Accueil" className="accueil">
              HOME
            </NavLink>
            <ul>
              <li>
                <NavLink to="/about" onClick={handleAboutClick} aria-label="Page about">
                  ABOUT
                </NavLink>
              </li>
              <li>
                <NavLink to="#project" onClick={handleWorkClick} aria-label="section work">
                  WORK
                </NavLink>
              </li>
              <li>
                <a href="https://www.instagram.com/direct/t/17847716492692779/" aria-label="formulaire de contact" target='_blank' rel="noreferrer">
                  CONTACT
                </a>
              </li>
            </ul>
          </nav>
        </AnimatedElement>
      </header>
      {children}

      {isVisible && (
        <button
          className="arrow-top-button"

          aria-label="Retour en haut"
        >
          <svg onClick={handleHomeClick} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
            <path fill="currentColor" fillRule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12m12.293 1.707a1 1 0 0 0 1.414-1.414l-3-3a1 1 0 0 0-1.414 0l-3 3a1 1 0 1 0 1.414 1.414L12 11.414z" clipRule="evenodd" />
          </svg>
        </button >
      )}
      <footer className='scroll-child'>
        <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(-40px)"}>
          <h2 className="end">
            THE
          </h2>
        </AnimatedElement>
        <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(40px)"}>
          <h3 className='end-of'>END.</h3>
        </AnimatedElement>
        <div className="footer-info">
          <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(40px)"}>
            <Link
              to="https://www.instagram.com/mr.rible/"
              aria-label="Lien vers intagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              INSTAGRAM
            </Link>
          </AnimatedElement>

          <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(40px)"}>
            <h4>CREDITS</h4>
          </AnimatedElement>
          <div className="mention-footer">
            <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(-40px)"}>
              <section className='link'>
                <p>DESIGNED BY</p>
                <Link
                  to="https://www.instagram.com/mr.rible/"
                  className="design"
                  aria-label="Lien vers Instagram de l'artiste"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  MR.RIBLE
                </Link>
              </section>
            </AnimatedElement>
            <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(40px)"}>
              <section className='link'>
                <p>DEVELOPED BY</p>
                <Link
                  to="https://www.jv-design-et-tech.fr/"
                  className="copyright"
                  aria-label="Lien vers Réalisateur web"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  JV design & tech
                </Link>
              </section>
            </AnimatedElement>
          </div>
        </div>
      </footer >
    </>
  );
};

export default Layout;