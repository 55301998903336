import React from 'react';
import { animated, useSpring } from "@react-spring/web";
import { useIntersection } from "react-use";

const AnimatedElement = ({ children, finalState, initialState, className }) => {
    const ref = React.useRef(null);
    const intersection = useIntersection(ref, {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
    });

    const springProps = useSpring({
        opacity: intersection && intersection.intersectionRatio >= 0.1 ? 1 : 0,
        transform:
            intersection && intersection.intersectionRatio >= 0.1
                ? finalState
                : initialState,
        config: { duration: 1000 },
    });

    return (
        <animated.div className={className} ref={ref} style={springProps}>
            {children}
        </animated.div>
    );
};

export default AnimatedElement