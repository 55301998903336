import { Link } from 'react-router-dom';
import Layout from '../components/layout';
import AnimatedElement from "../components/AnimatedElement";

const Dibby = () => {

    const handleReload = (route) => {
        window.location.href = route;
    }

    return (
        <Layout>
            <main className='conteneur-dibby'>
                <div className="hero">
                    <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(40px)"} className="image-container">
                        <a href='https://www.instagram.com/jucy_lab?igsh=MTQycXN5cHN4NDB1dQ=='> <img src="../../assets/dibby/project1.png" alt="Project 1 JucyLab" /></a>
                    </AnimatedElement>
                    <div className="text-container">
                        <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(-40px)"} className="title-content">
                            <h2>Jucy</h2>
                            <h3>lab</h3>
                        </AnimatedElement>
                        <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(40px)"} className="text-content">
                            <p>
                                En tant que directeur artistique
                                évoluant au sein d'un label de
                                musique et de médias basé à Genève,
                                j'ai eu le privilège de participer à
                                une multitude de projets passionnants.
                            </p>

                            <p>
                                Outre les clips, et les heures de studio
                                nous avons un concept bien à nous,
                                permettant de mettre en avant des
                                rappeurs petit ou grand à
                                la scène Suisse.
                            </p>


                            <p>
                                Ce concept vient se présenter sur
                                Instagram et Youtube sous forme
                                de photos, interview et freestyle
                                consistant à créé de toute pièce un
                                clip créatif reflétant l’univers
                                de l’artiste !
                            </p>

                            <p>Je vous invite à nous suivre<br />sur nos réseaux !</p>
                            <a href="https://www.instagram.com/jucy_lab/" target='_blank' rel="noopener noreferrer"><strong>@jucy_lab</strong></a>
                        </AnimatedElement>
                    </div>
                </div>

                <section className='scroll-child poster'>
                    <AnimatedElement finalState={'translateY(0) rotate(180deg)'} initialState={'translateY(-40px) rotate(180deg)'} className="date">
                        <p>(02.24)<br></br> *date de sortie</p>
                    </AnimatedElement>
                    <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(-40px)"} className="block-soundbox">
                        <h3>SoundBox</h3>
                        <p className='poster-text'>Dibby <br/>En tant qu’artiste genevois, il s’exprime à travers<br/>
                            le rap et utilise sans hésitation ses atouts LGBT<br/>
                            pour enrichir ses récits et ses chansons.
                        </p>
                    </AnimatedElement>
                    <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(40px)"} className="img-block">
                        <img src="../../assets/dibby/project1.2.png" alt="Project 1" />
                        <p className='under-img'>“ S/o à mes femboys, et femmes trans qui se maquillent beaucoup mieux que les nanas de mes ennemis. ”</p>
                    </AnimatedElement>
                    <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(40px)"}>
                        <p className='text'><strong>012</strong></p>
                    </AnimatedElement>
                </section>

                <section className='scroll-child showcase'>
                    <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(100px)"} className="title">
                        <h3>Dibby</h3>
                    </AnimatedElement>
                    <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(40px)"} className="img-block">
                        <img src="../assets/dibby/project1.3.png" alt='profil de Dibby'></img>
                    </AnimatedElement>
                </section>

                <div className='scroll-child profil'>
                    <section className='first-part'>
                        <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(-40px)"}>
                            <div className='work'>
                                <p><strong>Work <br></br>for</strong></p>
                                <h3>Jucy</h3>
                            </div>
                            <h4>lab</h4>
                        </AnimatedElement>
                        <AnimatedElement finalState={"translateY(0) rotate(180deg)"} initialState={"translateY(40px) rotate(180deg)"} className='number'>
                            <p>012</p>
                        </AnimatedElement>
                    </section>
                    <section className='second-part'>
                        <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(-40px)"} className='block-text'>
                            <p>
                                Statistiques globales Instagram & Youtube :<br></br>
                                +95 commentaires<br></br>+21.440 vues<br></br>+1.011 likes
                            </p>
                        </AnimatedElement>
                        <div className='block-background'></div>
                        <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(40px)"} className='block-img'>
                            <img src="../assets/dibby/project1.4.png" alt='profil de jucylab' />
                        </AnimatedElement>
                    </section>
                </div>

                <div className='scroll-child clip'>
                    <iframe
                        width="1200"
                        height="500"
                        title="YouTube video player"
                        src="https://www.youtube.com/embed/L871Cng0YaQ?si=Rn39yBxRqKw4sVvl"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        loading='lazy'
                        allowFullScreen
                    ></iframe>
                </div>
                <div className='scroll-child other-soundbox'>
                    <Link onClick={() => handleReload('/poissonchat')}>
                        <img src='../assets/dibby/project1.5.png' alt="montage des projets jucyLab" />
                    </Link>
                </div>
            </main>
        </Layout >
    );
};
export default Dibby;
