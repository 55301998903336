import { Link } from 'react-router-dom';
import Layout from '../components/layout';
import AnimatedElement from '../components/AnimatedElement';

const PoissonChat = () => {
    const handleReload = (route) => {
        window.location.href = route;
    }

    return (
        <Layout>
            <main className='conteneur-poisson-chat'>
                <section className='poster'>
                    <AnimatedElement finalState={'translateY(0) rotate(180deg)'} initialState={'translateY(-40px) rotate(180deg)'} className="date">
                        <p>(06.24)<br></br> *date de sortie</p>
                    </AnimatedElement>
                    <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(-40px)"} className="block-soundbox">
                        <h3>SoundBox</h3>
                        <p className='poster-text'>Poissonchat <br />À seulement 17 ans, Mathias nous transporte<br />
                            dans un univers old school et dévoile son talent<br />
                            d'écriture presque poétique pour dénoncer des<br />
                            faits réels se déroulant en Suisse.
                        </p>
                    </AnimatedElement>
                    <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(40px)"} className="img-block">
                        <img src="../../assets/poissonchat/project4.1.webp" alt="Un homme qui lie un livre en feu" />
                        <p className='under-img'>“ J’arrive en full Van Cleef, j’arrive avec le flow de Ra’s al ghul. ”</p>
                    </AnimatedElement>
                    <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(40px)"}>
                        <p className='text'><strong>021</strong></p>
                    </AnimatedElement>
                </section>

                <section className='scroll-child showcase'>
                    <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(100px)"} className="title">
                        <h3>Poissonchat</h3>
                    </AnimatedElement>
                    <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(40px)"} className="img-block">
                        <img src="../assets/poissonchat/project4.2.webp" alt="Poissonchat Project 4.2" />
                    </AnimatedElement>
                </section>

                <section className='scroll-child profil'>
                    <article className='first-part'>
                        <AnimatedElement finalState={"translateX(0)"} initialState={"translateX(-40px)"}>
                            <div className='work'>
                                <p><strong>Work <br></br>for</strong></p>
                                <h3>Jucy</h3>
                            </div>
                            <h4>lab</h4>
                        </AnimatedElement>
                        <AnimatedElement finalState={"translateY(0) rotate(180deg)"} initialState={"translateY(40px) rotate(180deg)"} className='number'>
                            <p>021</p>
                        </AnimatedElement>
                    </article>
                    <article className='second-part'>
                        <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(-40px)"} className='block-text'>
                            <p>
                                Statistiques globales Instagram & YouTube :<br></br>
                                + 36 commentaires <br></br> +10.416 vues <br></br>+740 likes
                            </p>
                        </AnimatedElement>
                        <div className='block-background'></div>
                        <AnimatedElement finalState={"translateY(0)"} initialState={"translateY(40px)"} className='block-img'>
                        <img src="../assets/poissonchat/person-project4.webp" alt="profil de poissonchat" />
                        </AnimatedElement>
                    </article>
                </section>

                <section className='scroll-child clip'>
                    <iframe
                        title="YouTube video player"
                        src="https://www.youtube.com/embed/uWateIt5HoQ?si=VYKXkf3A57Mq933U"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        loading='lazy'
                        allowFullScreen
                    ></iframe>
                </section>
                <section className='scroll-child other-soundbox'>
                    <Link onClick={() => handleReload('/khalifa')}>
                        <img src='../assets/poissonchat/project4.4.webp' alt="montage des projets jucyLab" />
                    </Link>
                </section>
            </main>
        </Layout>
    );
};

export default PoissonChat;
